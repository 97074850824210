module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"html","store":true},{"name":"uuid","store":true},{"name":"primary_tag","store":true},{"name":"published_at","store":true},{"name":"slug","store":true},{"name":"title","store":true,"attributes":{"boost":20}},{"name":"url","store":true}],"resolvers":{"GhostPost":{}},"filename":"search_index.json"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
